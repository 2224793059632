// Graph3DGlow.js
import React, { useRef, useEffect, useState } from 'react';
import ForceGraph3D from 'react-force-graph-3d';
import * as THREE from 'three'; 

const Graph3DGlow = ({ data, containerRef }) => {
  const fgRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 800, height: 800 });

  // Update graph dimensions when container size changes
  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const { clientWidth, clientHeight } = containerRef.current;
        setDimensions({
          width: clientWidth - 20, // 10px padding on both sides
          height: clientHeight - 20, // 10px padding on both sides
        });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, [containerRef]);

  // Customize node appearance for better contrast
  const nodeThreeObject = (node) => {
    const nodeMaterial = new THREE.MeshBasicMaterial({
      color: node.color || '#333', // Darker color for visibility on light background
      transparent: false,
      opacity: 1,
    });
    const sphere = new THREE.Mesh(new THREE.SphereGeometry(5), nodeMaterial);
    return sphere;
  };

  return (
    <>
      {data ? (
        <ForceGraph3D
          ref={fgRef}
          width={dimensions.width}
          height={dimensions.height}
          graphData={data}
          backgroundColor="rgba(0,0,0,0)" // Transparent background
          nodeLabel="id"
          nodeAutoColorBy="group"
          nodeThreeObject={nodeThreeObject}
          linkColor={() => '#333'} // Darker link color for contrast
          linkLabel={link => `${link.name || link.relationship_name || 'Unknown'}`}
        />
      ) : (
        <p>Loading graph...</p>
      )}
    </>
  );
};

export default Graph3DGlow;
