// GraphPage.js
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Graph3DGlow from './Graph3DGlow';
import Graph3DSpace from './Graph3DSpace';
import Graph2D from './Graph2D';
import './GraphPage.css';

const GraphPage = () => {
  const containerRef1 = useRef();
  const containerRef2 = useRef();
  const containerRef3 = useRef();
  
  const [graphData1, setGraphData1] = useState(null);
  const [graphData2, setGraphData2] = useState(null);
  const [graphData3, setGraphData3] = useState(null);

  // Use environment variable directly
  const defaultBaseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:8000';
  const [baseUrl] = useState(defaultBaseUrl);
    
  useEffect(() => {
    // Function to fetch graph info and traversal data
    const fetchGraphData = async () => {
      try {
        // Step 1: GET /graph_info/ to fetch all information about the graph
        const graphInfoResponse = await axios.get(`${baseUrl}/graph_info/`);
        const graphInfo = graphInfoResponse.data;
        console.log('Graph Info:', graphInfo);

        // Step 2: GET /groups/{group_name}/nodes to fetch the first node in the "machine" group
        const groupName = 'machine';  // Group name to fetch nodes from
        const groupNodesResponse = await axios.get(`${baseUrl}/groups/${groupName}/nodes`);
        const nodes = groupNodesResponse.data.nodes;
        console.log('Group Nodes:', nodes);

        if (nodes.length === 0) {
          throw new Error(`No nodes found in group: ${groupName}`);
        }

        const startNodeId = nodes[0]._id;  // Get the _id of the first node in the group
        console.log('Start Node ID:', startNodeId);

        // Step 3: GET /graph/traverse/ with the start_node_id
        const traverseResponse = await axios.get(`${baseUrl}/graph/traverse/`, {
          params: {
            start_node_id: startNodeId,
            max_depth: 3,  // Adjust the depth as needed
            direction: 'any'
          }
        });
        const traversalData = traverseResponse.data;
        console.log('Traversal Data:', traversalData);

        // Step 4: Clone the traversal data for each graph to ensure independence
        setGraphData1(JSON.parse(JSON.stringify(traversalData)));  // For Graph3DGlow
        setGraphData2(JSON.parse(JSON.stringify(traversalData)));  // For Graph3DSpace
        setGraphData3(JSON.parse(JSON.stringify(traversalData)));  // For Graph2D

      } catch (error) {
        console.error('Error fetching graph data:', error);
      }
    };

    fetchGraphData();
  }, [baseUrl]);

  return (
    <div className="graph-page-container">
      <Header />

      <div id="graph-container1" ref={containerRef1} className="graph-box">
        {graphData1 ? (
          <Graph3DGlow data={graphData1} containerRef={containerRef1} />
        ) : (
          <p>Loading Graph 1...</p>
        )}
      </div>

      <div id="graph-container2" ref={containerRef2} className="graph-box">
        {graphData2 ? (
          <Graph3DSpace data={graphData2} containerRef={containerRef2} />
        ) : (
          <p>Loading Graph 2...</p>
        )}
      </div>

      <div id="graph-container3" ref={containerRef3} className="graph-box">
        {graphData3 ? (
          <Graph2D data={graphData3} containerRef={containerRef3} />
        ) : (
          <p>Loading Graph 3...</p>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default GraphPage;
