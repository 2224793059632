// Graph3DSpace.js
import React, { useRef, useEffect, useState } from 'react';
import ForceGraph3D from 'react-force-graph-3d';
import * as THREE from 'three';

const Graph3DSpace = ({ data, containerRef }) => {
  const fgRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 800, height: 800 });

  // Update graph dimensions when container size changes
  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const { clientWidth, clientHeight } = containerRef.current;
        setDimensions({
          width: clientWidth - 20, // 10px padding on both sides
          height: clientHeight - 20, // 10px padding on both sides
        });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, [containerRef]);

  // Function to generate 3D text label for each link or node
  const createLabel = (text) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const fontSize = 100;

    canvas.width = 1024;
    canvas.height = 512;

    // Set up font and color
    context.font = `${fontSize}px Arial`;
    context.fillStyle = '#333';
    context.textAlign = 'center';
    context.textBaseline = 'middle';

    // Draw the text in the middle of the canvas
    context.fillText(text, canvas.width / 2, canvas.height / 2);

    const texture = new THREE.CanvasTexture(canvas);
    const material = new THREE.SpriteMaterial({ map: texture, transparent: true });
    const sprite = new THREE.Sprite(material);

    sprite.scale.set(10, 5, 1); // Adjust scale as needed

    return sprite;
  };

  return (
    <>
      {data ? (
        <ForceGraph3D
          ref={fgRef}
          width={dimensions.width}
          height={dimensions.height}
          graphData={data}
          backgroundColor="rgba(0,0,0,0)" // Transparent background
          linkColor={() => '#333'} // Show links with color
          nodeAutoColorBy="group"
          nodeLabel={node => `${node.id}`}
          linkWidth={0.2}
          linkOpacity={0.2}

          // Render link labels
          linkThreeObjectExtend={true}
          linkThreeObject={link => createLabel(link.name || link.relationship_name || 'Unknown')}
          linkPositionUpdate={(sprite, { start, end }) => {
            const middlePos = {
              x: (start.x + end.x) / 2,
              y: (start.y + end.y) / 2 + 2,  // Offset slightly above the link
              z: (start.z + end.z) / 2,
            };
            sprite.position.set(middlePos.x, middlePos.y, middlePos.z);
          }}

          // Render node labels
          nodeThreeObject={node => createLabel(node.id)}
          nodeThreeObjectExtend={true}
          nodePositionUpdate={(sprite, node) => {
            sprite.position.set(node.x, node.y + 1.5, node.z); // Offset label above the node
          }}
        />
      ) : (
        <p>Loading graph...</p>
      )}
    </>
  );
};

export default Graph3DSpace;
